<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
	<Search :bool='false' />
	<ListWork />
    <DrugPrice />
	<Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import DrugPrice from "@/components/DrugPrice.vue";
import ListWork from "@/components/ListWork.vue";
import Search from "@/components/Search.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
	Search,
	ListWork,
    DrugPrice,
	Footer
  },
};
</script>
<style lang="scss">
	.home{
		width: 1330px;
		margin: 0 auto;
		
	}
</style>
