<template>
	<div class="about">
		<Search :bool='false' />
		<ListWork />
		<div class="ziList">
			<Sort></Sort>
			<LeftList :bool='false' :num='1400'></LeftList>
		</div>
		<Footer />
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from "@/components/HelloWorld.vue";
	import ListWork from "@/components/ListWork.vue";
	import Search from "@/components/Search.vue";
	import Footer from "@/components/Footer.vue";
	import Sort from "@/components/Sort.vue";
	import LeftList from "@/components/LeftList.vue";
	

	export default {
		name: "Home",
		components: {
			Search,
			ListWork,
			Sort,
			Footer,
			LeftList
		},
		
	};
</script>
<style lang="scss">
	.about {
		width: 1330px;
		margin: 0 auto;

	}
	.ziList {
		width: 100%;
		// height: 100px;
		// background-color: #0f1;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
</style>
