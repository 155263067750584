<template>
	<div class="ziList">
		<Sch></Sch>
		<LeftList></LeftList>
	</div>
	
</template>

<script>
	import Sch from "@/components/Sch.vue";
	import LeftList from "@/components/LeftList.vue";
	export default {
	  name: "SchList",
	  components: {
		Sch,
		LeftList
	  },
	};
</script> 

<style scoped lang="scss">
	.ziList {
		width: 1330px;
		// height: 100px;
		// background-color: #0f1;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
</style>
