<template>
	<div class="about">
		<Search :bool='true' />
		<ListWork />
		<div class="ziList">
			<HospitalInfo></HospitalInfo>
			<!-- <LeftList :bool='false' :num='1400'></LeftList> -->
		</div>
		<Footer />
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from "@/components/HelloWorld.vue";
	import ListWork from "@/components/ListWork.vue";
	import Search from "@/components/Search.vue";
	import Footer from "@/components/Footer.vue";
	import HospitalInfo from "@/components/HospitalInfo.vue";
	// import LeftList from "@/components/LeftList.vue";
	

	export default {
		name: "Home",
		components: {
			Search,
			ListWork,
			HospitalInfo,
			Footer,
			// LeftList
		},
		
	};
</script>
<style lang="scss" scoped="scoped">
	.about {
		width: 1320px;
		margin: 0 auto;
	}
	.ziList {
		
		// height: 100px;
		// background-color: #0f1;
		
	}
</style>
