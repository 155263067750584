<template>
	<div class="product">
		<div class="title">
			<span @click="home">连云健 ></span>
			<span @click="back2">药品信息 ></span>
			<span @click="back1">{{list.cate1}} ></span>
			<span>药品详情</span>
		</div>
		<div class="productInfo">
			<img :src="list.img" alt="">
			<div>
				<h2>{{list.name}}</h2>
				<p class="p_2" ><span>{{list.appr_num}}</span></p>
				<p class="p_3" >{{list.price}}</p>
			</div>
		</div>
		<div class="info_1">
			<p>商品简介</p>
			<div class="infos">
				<div v-html="list.brief"></div>
				<div>{{list.func}}</div>
			</div>
		</div>
		<div class="info_2">
			<p>药品说明书</p>
			<div>
				<div v-html='list.instru'></div>
				<div>{{list.manu}}</div>
			</div>
			
		</div>
		<div class="info_3">
			请仔细阅读药品说明书注意事项,禁忌等内容,按照说明书或者在药师指导下购买和使用,将药品置于儿童无法触及的位置.
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {
		
		name: "Search",
		data() {
			return {
				list:[]
			}
	
		},
		props: {
			
		},
		watch: {
	
		},
		created() {
			
			const that = this;
			this.id = this.$route.query.id;

			axios.post(API_LIST.info, {
					id: that.id
				}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						// console.log(_d)
						that.list = _d.data.list;
						
					} else if (_d.data.status == 409) {
			
					}
			
			
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		mounted() {
			
		},
		methods: {
			back2(){
				this.$router.go(-2)
			},
			back1(){
				this.$router.go(-1)
			},
			home(){
				this.$router.push({
					path:'/',
					query:{
						
					}
				})
			},
			detail(_id){
				this.$router.push({
					path:'/ProductDetail',
					query:{
						id:_id
					}
				})
			},
			// 上一页
			shang() {
				const that = this;
				if (this.page == 1) {
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				} else {
					this.ind--;
					this.page--;
					if (this.page < this.num[0]) {
						that.num = this.generateArray(this.num[0] - 5, this.num[0] - 1)
						this.ind = 4;
					}
					axios.post(API_LIST.drug_list, {
							page: this.page,
							gc_name2:this.name
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.list = _d.data.list.list;
			
								that.counts = _d.data.list.page;
								if (_d.data.list.page == 0) {
									that.hidden = false;
									return false
								}
								if (that.num.length + 5 < that.counts) {
									that.num = this.generateArray(1, 5)
									that.show = true
								} else {
									that.num = that.counts
									that.show = false
								}
							} else if (_d.data.status == 409) {
			
							}
			
			
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},
			// 下一页
			xia() {
				const that = this;
				if (that.page == that.counts) {
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				} else {
					this.ind++;
					this.page++;
					if (this.page > this.num[4]) {
						that.num = this.generateArray(this.num[4] + 1, this.num[4] + 5)
						this.ind = 0;
					}
					axios.post(API_LIST.drug_list, {
							page: this.page,
							gc_name2:this.name
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.list = _d.data.list.list;
			
								that.counts = _d.data.list.page;
			
			
							} else if (_d.data.status == 409) {
			
							}
			
			
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			
			},
			// 点击选择
			click_id(item){
				const that = this;
				this.ind = item-1;
				this.page = item;
				
				axios.post(API_LIST.drug_list, {
						page: this.page,
						gc_name2:this.name
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							// console.log(_d)
							that.list = _d.data.list.list;
							that.counts = _d.data.list.page;
							
						} else if (_d.data.status == 409) {
							
						}
							
							
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			generateArray(start, end) {
				return Array.from(new Array(end + 1).keys()).slice(start)
			}
			
		},
		
	};
</script>

<style scoped="scoped" lang="scss">
	.product{
		width: 850px;
		.title{
			font-size: 18px;
			color: #333333;
			span:hover{
				cursor: pointer;
			}
		}
		.productInfo{
			margin: 20px 0 0 0;
			display: flex;
			flex-direction: row;
			img{
				width: 240px;
				height: 180px;
			}
			div{
				display: flex;
				flex-direction: column;
				margin: 0 0 0 10px;
				box-sizing: border-box;
				padding: 10px 0;
				h2{
					margin: 20px 0 0 0;
					font-size: 20px;
					font-weight: 700;
					color: #0078f0;
				}
				.p_1{
					width: 476px;
					height: 32px;
					font-size: 12px;
					overflow: hidden;
					color: #FD3030;
				}
				.p_2{
					margin: 30px 0;
					span{
						background-color: #a5d2ff;
						color: #0078f0;
						box-sizing: border-box;
						padding: 3px 5px;
						font-size: 14px;
					}
					// width: 50px;
				}
				.p_3{
					display: flex;
					flex-direction: row;
					span{
						color: #F83935;
						margin: 0 0 0 10px;
						font-weight: 800;
					}
				}
				.p_4{
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 40px 0 0 0;
					img{
						width: 15px;
						height: 20px;
						margin: 0 5px;
					}
					p{
						font-size: 16px;
						color: #7d7d7d;
						position: relative;
						span{
							color: #F83935;
							cursor: pointer;
						}
						
						img{
							position: absolute;
							width: 93px;
							height: 105px;
							top: -100px;
							right: 0;
							margin: 0 0px;
							// display: none;
							display: none;
						}
						span:hover .img{
							display: block;
						}
					}
				}
			}
		}
		.info_1,.info_2{
			display: flex;
			flex-direction: column;
			margin: 20px 0 0 0;
			p{
				color: #333333;
				font-size: 18px;
				padding: 0 0 0 10px;
				box-sizing: border-box;
				border-left: 3px solid #0078f0;
				font-weight: 700;
				
			}
			.infos{
				margin: 10px 0 0 0;
				font-size: 14px;
				line-height: 25px;
				color: #000000;
			}
		}
		.info_3{
			font-size: 14px;
			color: #666666;
			background: #f0f0f0;
			text-align: center;
			margin: 30px 0 0 0;
			padding: 5px 0;
		}
		
	}
</style>
