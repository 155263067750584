<template>
	<div class="details">
		<ArticleDetail />
		<LeftList></LeftList>
	</div>
</template>

<script>
	import ArticleDetail from "@/components/ArticleDetail.vue";
	import LeftList from "@/components/LeftList.vue";
	export default {
	  name: "detail",
	  components: {
		ArticleDetail,
		LeftList
	  },
	};
</script>

<style scoped="scoped" lang="scss">
	.details {
		width: 1320px;
		// height: 100px;
		// background-color: #0f1;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
</style>
