<template>
	<div class="about">
		<Search :bool='true' />
		<ListWork />
		<SchList />
		<Footer />
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from "@/components/HelloWorld.vue";
	import ListWork from "@/components/ListWork.vue";
	import Search from "@/components/Search.vue";
	import Footer from "@/components/Footer.vue";
	import SchList from "@/components/SchList.vue";

	export default {
		name: "Home",
		components: {
			Search,
			ListWork,
			SchList,
			Footer
		},
		
	};
</script>
<style lang="scss">
	.home {
		height: 100%;

	}
</style>
