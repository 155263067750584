<template>
	<div class="drugPrice">
		<div class="drug">
			<DrugInfo></DrugInfo>
			<LeftList :bool='false' :num='1800' />
		</div>
		
	</div>
	
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		
	} from 'element-plus'
	import DrugInfo from "./DrugInfo.vue";
	import LeftList from "./LeftList.vue";
	
	
	
	export default {
		name: "HelloWorld",
		props: {
			
		},
		components: {
			DrugInfo,
			LeftList
		},
		data() {
			return {
				
			}
	
		},
		watch: {
	
			
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
		
		}
	};
</script>

<style scoped lang="scss">
	.drugPrice{
		width: 100%;
	}
	.drug {
		width: 1330px;
		// height: 100px;
		// background-color: #0f1;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
</style>
