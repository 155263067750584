<template>
	<div class="about">
		<Search :bool='true' />
		<ListWork />
		<ZiList />
		<Footer />
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from "@/components/HelloWorld.vue";
	import ListWork from "@/components/ListWork.vue";
	import Search from "@/components/Search.vue";
	import Footer from "@/components/Footer.vue";
	import ZiList from "@/components/ZiList.vue";

	export default {
		name: "Home",
		components: {
			Search,
			ListWork,
			ZiList,
			Footer
		},
		
	};
</script>
<style lang="scss">
	.about {
		width: 1320px;
		margin: 0 auto;
	}
</style>
