<template>
	<div class="leftList">
		<div class="top">
			<div class="renqi">人气推荐</div>
			<div class="one" v-for="item in list" @click="detail(item.id)">
				<div class="img"><img :src="item.image" alt="" /></div> 
				<div>
					<p>{{item.title}}</p>
					<span>发布时间：{{item.addtime}}</span>
				</div>
			</div>
		</div>
		<div class="hot" v-if="bools">
			<p class="title">热门药物查询</p>
			<div class="row">
				<img class="row1" src="../assets/ceshi8.png" alt="">
				<div class="row2"></div>
				<div class="row3"></div>
				<div class="row4"></div>
				<div class="row5"></div>
				<div class="row6"></div>
				<img class="row7" src="../assets/ceshi8.png" alt="">
				<div class="row8"></div>
				<div class="row9"></div>
				<div class="row10"></div>
				<div class="row11"></div>
				<img class="row12" src="../assets/ceshi8.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import qs from 'qs'
	
	export default {
		name: "HelloWorld",
		props: {
			bool:Boolean,
			num:Number
		},
		data() {
			return {
				list:[],
				dataList:[],
				show:false,
				_top:this.bool,
				bools:this.bool
			}
	
		},
		watch: {
	
	
		},
		computed:{
			list(){
				if(this._top){
					return this.dataList.slice(0,2)
				}else{
					return this.dataList
				}
			}
		},
		created() {
			
		},
		mounted() {
			console.log(API_LIST.sidebar)
			const that = this;
			axios.post(API_LIST.sidebar, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						// console.log(_d)
						that.dataList = _d.data.list
						
						
					} else if (_d.data.status == 409) {
						
					}
					
								
				})
				.catch(function(error) {
					console.log(error);
				});
			window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			
			detail(_id) {
				this.$router.push({
					path: '/Detail',
					query:{
						id:_id
					}
				})
			},
			more(){
				this.$router.push({
					path: '/List',
					query:{
						id:2
					}
				})
			},
			handleScroll() {
				let scrollY = window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				let scrollObj = document.querySelector(".leftList");
				//scrollObj 这个是下图右边答题卡的div
				// console.log(scrollY)
				if (scrollY > this.num) {
					//这个358就是上面的距离
					scrollObj.style.position = "fixed";
					scrollObj.style.zIndex = 1000000;
					scrollObj.style.right = "110px";
					scrollObj.style.bottom = 200+'px';
				} else {
					scrollObj.style.position = "static";
					scrollObj.style.marginTop = "0";
				}
			},
			mores(){
				this._top = !this._top;
			}
		},
		destroyed() {
			//销毁监听
			console.log(333)
			window.removeEventListener("scroll", this.handleScroll);
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.leftList{
		display: flex;
		flex-direction: column;
		width: 330px;
		.top,
		.center{
			display: flex;
			flex-direction: column;
			border: 1px solid #e6e6e6;
			.renqi{
				width: 100%;
				height: 50px;
				background: #f0f0f0;
				font-size: 20px;
				font-weight: 700;
				box-sizing: border-box;
				padding: 0 0 0 10px;
				line-height: 50px;
			}
			.one{
				display: flex;
				flex-direction: row;
				background-color: #fff;
				box-sizing: border-box;
				padding: 10px;
				.img{
					width: 104px;
					height: 78px;
					img{
						width: 100%;
						height: 100%;
					}
				}
				div{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: 0 0 0 5px;
					p{
						font-size: 16px;
						color: #000000;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}
					P:hover{
						color:#0078F0;
					}
					span{
						color: #999999;
						font-size: 14px;
					}
				}
				
			}
			.one:hover{
				cursor: pointer;
			}
		}
		.bottoms{
			position: relative;
			img{
				width: 100%;
				
			}
			.dibu{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				.mask{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					background-color: #000;
					opacity: 0.3;
					height:30px;
				}
				p{
					position: absolute;
					bottom: 5px;
					left: 0;
					right: 0;
					margin: auto;
					z-index: 10;
					width: 100%;
					font-size: 16px;
					font-family: MicrosoftYaHei;
					color: #FFFFFF;
					width: 300px;
				}
			}
		}
		.zhan{
			width: 200px;
			height: 35px;
			background-color: #aaa;
			margin: 0 auto;
			overflow: hidden;
			position: relative;
		}
		.zhan2::before{
			content: '';
			width: 10px;
			height: 60px;
			background-color: #fff;
			// border-left: 10px solid #fff;
			position: absolute;
			left: 70px;
			// right: 0;
			top: -12px;
			transform: rotate(-70deg);
		}
		.zhan2::after{
			content: '';
			width: 10px;
			height: 60px;
			background-color: #fff;
			// border-left: 10px solid #fff;
			position: absolute;
			left: 123px;
			// right: 0;
			top: -12px;
			transform: rotate(70deg);
		}
		.zhan3::before{
			content: '';
			width: 10px;
			height: 60px;
			background-color: #fff;
			// border-left: 10px solid #fff;
			position: absolute;
			left: 70px;
			// right: 0;
			top: -12px;
			transform: rotate(70deg);
		}
		.zhan3::after{
			content: '';
			width: 10px;
			height: 60px;
			background-color: #fff;
			// border-left: 10px solid #fff;
			position: absolute;
			left: 123px;
			// right: 0;
			top: -12px;
			transform: rotate(-70deg);
		}
		.hot{
			width: 249px;
			display: flex;
			flex-direction: column;
			margin: 30px 0 0 0;
			.title{
				font-size: 15px;
				margin: 0 0 20px 0;
				color: #3A3A3A;
			}
			.row{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				height: 370px;
				.row1{
					width: 173px;
					height: 66px;
				}
				.row2{
					width: 66px;
					height: 66px;
					background-color: #E2F4D8;
				}
				.row3{
					width: 66px;
					height: 66px;
					background-color: #D8F1F4;
				}
				.row4{
					width: 66px;
					height: 66px;
					background-color: #F4EBD8;
				}
				.row5{
					width: 101px;
					height: 66px;
					background-color: #D8F4E7;
				}
				.row6{
					width: 66px;
					height: 66px;
					background-color: #E2F4D8;
				}
				.row7{
					width: 173px;
					height: 66px;
				}
				.row8{
					width: 101px;
					height: 66px;
					background-color: #D8F4E7;
				}
				.row9{
					width: 66px;
					height: 66px;
					background-color: #D8F1F4;
				}
				.row10{
					width: 66px;
					height: 66px;
					background-color: #F4EBD8;
				}
				.row11{
					width: 66px;
					height: 66px;
					background-color: #E2F4D8;
				}
				.row12{
					width: 173px;
					height: 66px;
				}
				
			}
		}
	}
</style>
