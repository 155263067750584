<template>
	<div class="detail">
		<Search :bool='true' />
		<ListWork />
		<Details />
		
		<Footer />
		
	</div>
	
</template>

<script>
	import ListWork from "@/components/ListWork.vue";
	import Search from "@/components/Search.vue";
	import Details from "@/components/Details.vue";
	import Footer from "@/components/Footer.vue";
	
	export default {
	  name: "detail",
	  components: {
		Search,
		ListWork,
		Footer,
		Details
	  },
	};
</script> 

<style scoped lang="scss">
	.detail {
		
			width: 1320px;
			margin: 0 auto;
		
	}
	
</style>
