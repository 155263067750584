<template>
	<div class="sort">
		<div class="title"><span @click="home">连云健</span> > <span @click="back">药品信息</span> > <span>{{name}}</span></div>
		<div class="productList">
			<ul>
				<li v-for="item in list" @click="detail(item.id)">
					<img :src="item.img" alt="">
					<div>
						<p>{{item.name}}</p>
						<div>{{item.price}}</div>
					</div>
					
				</li>
			</ul>
		</div>
		<div class="fen">
			<!-- <ul v-if="hidden"> -->
				<!-- <li class="shang" @click="shang">上一页</li>
				<li :class="{bg:ind == index}" v-for="(item,index) in num" @click="click_id(item)">{{item}}</li>
				<li class="xia" @click="xia">下一页</li> -->
				<!-- <li class="xiaFive" v-if="show">下5页</li> -->
				
			<!-- </ul> -->
			<el-pagination
			  background
			  layout="prev, pager, next"
			  @prev-click='shang'
			  @next-click='xia'
			  @current-change='click_id'
			  :page-count='counts'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {
		
		name: "Search",
		data() {
			return {
				page:1,
				name:String,
				hidden:true,
				num:[],
				ind:0,
				list:[],
			}
	
		},
		props: {
			
		},
		watch: {
	
		},
		created() {
			
			const that = this;
			this.name = this.$route.query.data_name;
			console.log(that.list)
			axios.post(API_LIST.drug_list, {
					page: that.page,
					gc_name2: that.name
				}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					if (_d.data.status == 200) {
						// console.log(_d)
						that.list = _d.data.list.list;
						console.log('0000',that.list)
						that.counts = _d.data.list.page;
						
					} else if (_d.data.status == 409) {
			
					}
			
			
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		mounted() {
			
		},
		methods: {
			// 点击连云健
			home(){
				this.$router.push({
					path:'/'
				})
			},
			// 点击药品信息
			back(){
				this.$router.go(-1)
			},
			
			detail(_id){
				this.$router.push({
					path:'/ProductDetail',
					query:{
						id:_id
					}
				})
			},
			// 上一页
			shang() {
				const that = this;
				if (this.page == 1) {
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				} else {
					this.ind--;
					this.page--;
					if (this.page < this.num[0]) {
						that.num = this.generateArray(this.num[0] - 5, this.num[0] - 1)
						this.ind = 4;
					}
					axios.post(API_LIST.drug_list, {
							page: this.page,
							gc_name2:this.name
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.list = _d.data.list.list;
			
								that.counts = _d.data.list.page;
								if (_d.data.list.page == 0) {
									that.hidden = false;
									return false
								}
								if (that.num.length + 5 < that.counts) {
									that.num = this.generateArray(1, 5)
									that.show = true
								} else {
									that.num = that.counts
									that.show = false
								}
							} else if (_d.data.status == 409) {
			
							}
			
			
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},
			// 下一页
			xia() {
				const that = this;
				if (that.page == that.counts) {
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				} else {
					this.ind++;
					this.page++;
					if (this.page > this.num[4]) {
						that.num = this.generateArray(this.num[4] + 1, this.num[4] + 5)
						this.ind = 0;
					}
					axios.post(API_LIST.drug_list, {
							page: this.page,
							gc_name2:this.name
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.list = _d.data.list.list;
			
								that.counts = _d.data.list.page;
			
			
							} else if (_d.data.status == 409) {
			
							}
			
			
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			
			},
			// 点击选择
			click_id(item){
				const that = this;
				this.ind = item-1;
				this.page = item;
				
				axios.post(API_LIST.drug_list, {
						page: this.page,
						gc_name2:this.name
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							// console.log(_d)
							that.list = _d.data.list.list;
							that.counts = _d.data.list.page;
							
						} else if (_d.data.status == 409) {
							
						}
							
							
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			generateArray(start, end) {
				return Array.from(new Array(end + 1).keys()).slice(start)
			}
			
		},
		
	};
</script>

<style lang="scss" scoped="scoped">
	.sort{
		width: 850px;
		.title{
			span{
				cursor: pointer;
				color: #333333;
				font-size: 20px;
			}
		}
		.brand{
			display: flex;
			flex-direction: row;
			width: 850px;
			border: 1px solid #d3d3d3;
			box-sizing: border-box;
			padding: 5px;
			justify-content: space-between;
			ul{
				display: flex;
				flex-direction: row;
				span{
					color: #939393;
					margin:  0 0 0 10px;
				}
				li{
					margin: 0 10px 0 20px;
					color: #939393;
				}
			}
			.more{
				display: flex;
				flex-direction: row;
				align-items: center;
				div{
					width: 16px;
					height: 16px;
					background: #39A49B;
					margin: 0 5px 0 0;
					position: relative;
					border-radius: 4px;
				}
				div::before{
					content: '';
					width: 12px;
					height: 1px;
					background-color: #fff;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				}
				div::after{
					content: '';
					width: 1px;
					height: 12px;
					background-color: #fff;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		}
		.brandInfo{
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			padding: 10px 100px;
			border: 1px solid #d3d3d3;
			margin: 10px 0 0 0;
			.zimu{
				display: flex;
				flex-direction: row;
				width: 100%;
				justify-content: space-between;
				li{
					font-size: 16px;
					color: #a7a7a7;
				}
				
			}
			.name{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				li{
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 5px 40px 1px 20px;
					.input_check {
					    -moz-appearance: none;
					    appearance: none;
					    -webkit-appearance: none;
					    outline: none;
					    display: none;
					}
					/* 未选 */
					.input_check{
					    display: inline-block;
					    border: 1px solid #ccc;
					    border-radius: 2px;
					    width: 12px;
					    height: 12px;
					}
					/* 已选 */
					.input_check:checked {
					    display: inline-block;
					    background-image: url('../assets/check.png');
					    background-repeat: no-repeat;  
					    background-position: 0px 0px; 
					    width: 12px;
					    height: 12px;
					    border: none;
					} 
					
					p{
						font-size: 14px;
						color: #a7a7a7;
						margin: 0 0 0 5px;
					}
				}
				div{
					display: flex;
					flex-direction: row;
					margin: 20px 0 0 0;
					button{
						width: 68px;
						height: 22px;
						background: #D8D8D8;
						font-size: 14px;
						color: #fff;
						line-height: 22px;
						margin: 0 0 0 220px;
					}
					.quxiao{
						border: 1px solid #979797;
						background-color: #F2F2F2;
						color: #000;
						margin: 0 0 0 20px;
					}
				}
			}
		}
		.productList{
			display: flex;
			flex-direction: row;
			width: 100%;
			margin: 20px 0 0 0;
			ul{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				li{
					display: flex;
					flex-direction: row;
					margin: 0 0 15px 0;
					img{
						width: 184px;
						height: 184px;
					}
					div{
						margin: 5px 0 0 10px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 200px;
						p{
							margin: 30px 0 0 0;
							color: #0078F0;
							font-size: 20px;
						}
						div{
							margin: 0 0 30px 0;
							font-size: 16px;
							color: #999999;
						}
					}
				}
				li:hover{
					cursor: pointer;
				}
			}
		}
		.fen {
			display: flex;
			flex-direction: column;
			margin: 50px auto 0;
			width: 500px;
		
			// width: 300px;
			ul {
		
				display: flex;
				flex-direction: row;
		
				li {
					background-color: #add5d4;
					margin-left: 5px;
					color: #333;
					width: 36px;
					height: 36px;
					line-height: 36px;
					text-align: center;
		
					// padding: 10px;
		
				}
		
				.bg {
					background-color: #39A49B !important;
				}
		
				li:hover {
					background-color: #39A49B !important;
					cursor: pointer;
				}
		
				.shang,
				.xia,
				.xiaFive {
					width: 87px;
					height: 37px;
				}
		
			}
		}
	}
</style>
