<template>
	<!-- <div class="zi" :view-id="newRender">

		<ul class="list">
			<li v-for="(itemChild,index) in listData" @click="detail(itemChild.id)">
				<img :src="itemChild.image" alt="">
				<div class="right">
					<h3>{{itemChild.title}}</h3>
					<p>{{itemChild.summary}}<span>[详细]</span></p>
					<div class="bottom">
						<div class="lf">
							<span>{{itemChild.attr_name}}</span>
						</div>
						<span class="rg">
							阅读全文
						</span>
					</div>
				</div>
			</li>
		</ul>
		<div class="fen">
			<ul v-if="hidden">
				<li class="shang" @click="shangs">上一页</li>
				<li :class="{bg:ind == index}" v-for="(item,index) in num" @click="click_ids(item)">{{item}}</li>
				<li class="xia" @click="xias">下一页</li>
			</ul>
		</div>
	</div> -->
	<div class="zi">
		<div class="tops">
			<div class="chooseList">
				<ul class="chooseUl">
					<li :class="[{'choose':chooseId == index}]" @click="choose(item.id,index)" v-for="(item,index) in list">{{item.name}}</li>
				</ul>
			</div>
			
			<ul class="list">
				<li v-for="(itemChild,index) in listData" @click="detail(itemChild.id)">
					<img :src="itemChild.image" alt="">
					<div class="right">
						<h3>{{itemChild.title}}</h3>
						<!-- <p>{{itemChild.summary}}<span>[详细]</span></p> -->
						<div class="bottom">
							<div class="lf">
								<!-- <span>8分钟前</span> -->
								<span>发布时间：{{itemChild.addtime}}</span>
							</div>
							<span class="rg">
								阅读全文
							</span>
						</div>
					</div>
				</li>
			</ul>
			
		</div>
		<div class="fen">
			<!-- <ul v-if="hidden">
				<li class="shang" @click="shang">上一页</li>
				<li :class="{bg:ind == index}" v-for="(item,index) in num" @click="click_id(item)">{{item}}</li>
				<li class="xia" @click="xia">下一页</li>
			</ul> -->
			<el-pagination
			  background
			  layout="prev, pager, next"
			  @prev-click='shang'
			  @next-click='xia'
			  @current-change='click_id'
			  :page-count='pages'>
			</el-pagination>
		</div>
		
	</div>

</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {

		name: "Zi",
		props: {

		},
		data() {
			return {
				list: [],
				listData: [],
				val: '',
				page: 1,
				chooseId: 0,
				show: false,
				counts: 0, // 页数
				ind: 0,
				num: 0,
				hidden: true,
			}

		},
		watch: {

		},
		computed: {

			newRender: function() {
				const that = this;
				that.val = this.$route.query.val
				axios.post(API_LIST.select, {
						keywords: that.val,
						page: that.page
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							// console.log(_d)
							that.listData = _d.data.list.list;

							that.chooseId = 0;
							that.counts = _d.data.list.page
							if (_d.data.list.page == 0) {
								that.hidden = false;
								return false
							} else {
								that.hidden = true;
							}
							if (that.num + 5 < that.counts) {
								that.num = 5
								that.show = true
							} else {
								that.num = that.counts
								that.show = false
							}
						} else if (_d.data.status == 409) {

						}


					})
					.catch(function(error) {
						console.log(error);
					});
				// window.addEventListener('List');

				return this.id
			}
		},

		created() {
			const that = this;

			that.val = this.$route.query.val


			axios.post(API_LIST.select, {
					keywords: that.val,
					page: that.page
				}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						// console.log(_d)
						that.listData = _d.data.list.list;

						that.counts = _d.data.list.page
						if (_d.data.list.page == 0) {
							that.hidden = false;
							return false
						} else {
							that.hidden = true;
						}
						if (that.num + 5 < that.counts) {
							that.num = 5
							that.show = true
						} else {
							that.num = that.counts
							that.show = false
						}
					} else if (_d.data.status == 409) {

					}


				})
				.catch(function(error) {
					console.log(error);
				});
		},
		mounted() {
			
		},
		

		methods: {
			search() {
				const that = this;
				console.log(that.$data.val)
			},
			detail(_id) {
				this.$router.push({
					path: '/Detail',
					query: {
						id: _id
					}
				})
			},
			
			
			// 上一页
			shangs() {
				const that = this;
				if (that.page == 1) {
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				} else {
					that.ind--;
					that.page--;
					axios.post(API_LIST.select, {
							keywords: that.val,
							page: that.page
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.listData = _d.data.list.list;

								that.counts = _d.data.list.page
								if (_d.data.list.page == 0) {
									that.hidden = false;
									return false
								} else {
									that.hidden = true;
								}
								if (that.num + 5 < that.counts) {
									that.num = 5
									that.show = true
								} else {
									that.num = that.counts
									that.show = false
								}
							} else if (_d.data.status == 409) {

							}


						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},
			// 下一页
			xias() {
				const that = this;
				if (that.page == that.counts) {
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				} else {
					that.ind++;
					that.page++;
					axios.post(API_LIST.select, {
							keywords: that.val,
							page: that.page
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.listData = _d.data.list.list;

								that.counts = _d.data.list.page
								if (_d.data.list.page == 0) {
									that.hidden = false;
									return false
								} else {
									that.hidden = true;
								}
								if (that.num + 5 < that.counts) {
									that.num = 5
									that.show = true
								} else {
									that.num = that.counts
									that.show = false
								}
							} else if (_d.data.status == 409) {

							}


						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},
			click_ids(_id) {
				const that = this;
				that.ind = _id - 1;
				that.page = _id;

				axios.post(API_LIST.select, {
						keywords: that.val,
						page: that.page
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							// console.log(_d)
							that.listData = _d.data.list.list;

							that.counts = _d.data.list.page
							if (_d.data.list.page == 0) {
								that.hidden = false;
								return false
							} else {
								that.hidden = true;
							}
							if (that.num + 5 < that.counts) {
								that.num = 5
								that.show = true
							} else {
								that.num = that.counts
								that.show = false
							}
						} else if (_d.data.status == 409) {

						}


					})
					.catch(function(error) {
						console.log(error);
					});


			}
		},
		
	};
</script>

// <style scoped="scoped" lang="scss">
// 	.zi {
// 		width: 850px;

// 		.chooseList {
// 			display: inline-block;

// 			ul {
// 				display: flex;
// 				flex-direction: row;
// 				justify-content: flex-start;
// 				padding: 5px;
// 				border-bottom: 1px solid #CDCDCD;

// 				li {
// 					margin: 0 10px;
// 					font-size: 16px;
// 					font-family: MicrosoftYaHei;
// 					color: #333333;

// 				}

// 				.choose {
// 					color: #39A49B !important;
// 					position: relative;
// 				}

// 				.choose::after {
// 					content: '';
// 					width: 90%;
// 					position: absolute;
// 					left: 0;
// 					right: 0;
// 					margin: auto;
// 					bottom: -6px;
// 					height: 2px;
// 					background-color: #39A49B;
// 				}
// 			}

// 		}

// 		.list {
// 			display: flex;
// 			flex-direction: column;

// 			li {
// 				display: flex;
// 				flex-direction: row;
// 				margin: 10px 0 0 0;
// 				padding: 0 0 10px 0;
// 				border-bottom: 1px dashed #DBDBDB;

// 				img {
// 					width: 200px;
// 					height: 130px;

// 				}

// 				.right {
// 					width: 533px;
// 					margin: 0 0 0 25px;

// 					h3 {
// 						font-size: 16px;
// 						font-family: MicrosoftYaHei;
// 						color: #39A49B;
// 					}

// 					p {
// 						font-size: 12px;
// 						font-family: MicrosoftYaHei;
// 						color: #666666;
// 						height: 48px;
// 						margin: 13px 0 30px 0;
// 					}

// 					.bottom {
// 						display: flex;
// 						flex-direction: row;
// 						justify-content: space-between;

// 						.lf {
// 							display: flex;
// 							flex-direction: row;

// 							span {
// 								font-size: 12px;
// 								font-family: MicrosoftYaHei;
// 								color: #999999;
// 								margin: 0 10px 0 0;
// 							}
// 						}

// 						.rg {
// 							font-size: 10px;
// 							font-family: MicrosoftYaHei;
// 							color: #39A49B;
// 							padding: 0 15px 0 0;
// 							position: relative;
// 						}


// 						.rg::after {
// 							content: '';
// 							position: absolute;
// 							right: 0;
// 							top: 0px;
// 							bottom: 0;
// 							margin: auto;
// 							width: 5px;
// 							height: 5px;
// 							border-right: 1px solid #39A49B;
// 							border-bottom: 1px solid #39A49B;
// 							transform: rotate(-45deg);
// 						}

// 						.rg::before {
// 							content: '';
// 							position: absolute;
// 							right: 5px;
// 							top: 0px;
// 							bottom: 0;
// 							margin: auto;
// 							width: 5px;
// 							height: 5px;
// 							border-right: 1px solid #39A49B;
// 							border-bottom: 1px solid #39A49B;
// 							transform: rotate(-45deg);
// 						}
// 					}
// 				}
// 			}
// 		}

// 		.fen {
// 			display: flex;
// 			flex-direction: column;
// 			margin: 50px auto 0;
// 			width: 500px;

// 			// width: 300px;
// 			ul {

// 				display: flex;
// 				flex-direction: row;

// 				li {
// 					background-color: #add5d4;
// 					margin-left: 5px;
// 					color: #333;
// 					width: 36px;
// 					height: 36px;
// 					line-height: 36px;
// 					text-align: center;

// 					// padding: 10px;

// 				}

// 				.bg {
// 					background-color: #39A49B !important;
// 				}

// 				li:hover {
// 					background-color: #39A49B !important;
// 					cursor: pointer;
// 				}

// 				.shang,
// 				.xia,
// 				.xiaFive {
// 					width: 87px;
// 					height: 37px;
// 				}

// 			}
// 		}
// 	}
// </style>
<style scoped="scoped" lang="scss">
	.zi {
		width: 850px;
		.tops{
			display: flex;
			flex-direction: row;
		}
		.chooseList {
			display: inline-block;
			

			ul {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				li {
					margin: 10px 0;
					font-size: 16px;
					color: #000;
					width: 130px;
					height: 46px;
					background: #F2F2F2;
					text-align: center;
					line-height: 46px;
				}
				li:hover{
					cursor: pointer;
				}

				.choose {
					color: #fff !important;
					background-color: #0078f0;
				}
			}

		}

		.list {
			display: flex;
			flex-direction: column;
			margin: 0 0px 0 20px;
			li {
				display: flex;
				flex-direction: row;
				margin: 10px 0 0 0;
				padding: 10px;
				box-sizing: border-box;
				border-bottom: 1px solid #f0f0f0;
				cursor: pointer;

				img {
					width: 200px;
					height: 130px;

				}

				.right {
					width: 533px;
					margin: 0 0 0 25px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					h3 {
						font-size: 20px;
						font-family: MicrosoftYaHei;
						color: #000;
					}
					h3:hover{
						color: #0078F0;
					}

					p {
						font-size: 12px;
						font-family: MicrosoftYaHei;
						color: #666666;
						height: 48px;
						margin: 13px 0 30px 0;
					}

					.bottom {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.lf {
							display: flex;
							flex-direction: row;

							span {
								font-size: 12px;
								font-family: MicrosoftYaHei;
								color: #999999;
								margin: 0 10px 0 0;
							}
						}

						.rg {
							font-size: 10px;
							font-family: MicrosoftYaHei;
							color: #39A49B;
							padding: 0 15px 0 0;
							position: relative;
						}


						.rg::after {
							content: '';
							position: absolute;
							right: 0;
							top: 0px;
							bottom: 0;
							margin: auto;
							width: 5px;
							height: 5px;
							border-right: 1px solid #39A49B;
							border-bottom: 1px solid #39A49B;
							transform: rotate(-45deg);
						}

						.rg::before {
							content: '';
							position: absolute;
							right: 5px;
							top: 0px;
							bottom: 0;
							margin: auto;
							width: 5px;
							height: 5px;
							border-right: 1px solid #39A49B;
							border-bottom: 1px solid #39A49B;
							transform: rotate(-45deg);
						}
					}
				}
			}
			li:hover{
				box-shadow: 0 0 15px #ccc;
			}
		}

		.fen {
			display: flex;
			flex-direction: column;
			margin: 50px auto 0;
			width: 500px;

			// width: 300px;
			ul {

				display: flex;
				flex-direction: row;

				li {
					background-color: #add5d4;
					margin-left: 5px;
					color: #333;
					width: 36px;
					height: 36px;
					line-height: 36px;
					text-align: center;

					// padding: 10px;

				}

				.bg {
					background-color: #39A49B !important;
				}

				li:hover {
					background-color: #39A49B !important;
					cursor: pointer;
				}

				.shang,
				.xia,
				.xiaFive {
					width: 87px;
					height: 37px;
				}

			}
		}
	}
</style>
