<template>
	<div class="articeDetail" :view-id="newRender">
		<div class="tops">
			<img src="../assets/logo.png" alt="">
			<div>
				<span>连云健 ></span>
				<span>医药新闻 ></span>
				<span>医药新闻 ></span>
				<span>正文</span>
			</div>
			
		</div>
		<h2>{{list.title}}</h2>
		<div class="title">
			<span>来源 : {{list.from_where}}</span>
			<span>{{list.addtime}}</span>
			<span style="border: none;">人气:{{list.click}}次</span>
		</div>
		<div class="dao"><span>导读:</span>{{list.summary}}</div>
		<img class="image" :src="list.image" alt="">
		<div class="Html" v-html="list.content">
			
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {

		name: "articleDetail",
		props: {
			
		},
		data() {
			return {
				list: [],
				nav_id:'',
			}

		},
		watch: {

		},
		computed: {
			newRender: function() {
				const that = this;
				that.nav_id = this.$route.query.id
				if(this.$route.query.sort == undefined){
					axios.post(API_LIST.content, {
							article_id: that.nav_id,
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								that.list = _d.data.list[0]
							} else if (_d.data.status == 409) {
					
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				}else{
					axios.post(API_LIST.banner_content, {
							banner_id: that.nav_id,
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								that.list = _d.data.list[0]
								const regex = new RegExp('<img', 'gi')
								that.list.content = that.list.content.replace(regex,"<img style='width:100%'");
							} else if (_d.data.status == 409) {
					
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				}
				
			}
		},

		created() {
			const that = this;
			that.nav_id = this.$route.query.id
			console.log(this.$route.query.sort)
			if(this.$route.query.sort == undefined){
				axios.post(API_LIST.content, {
						article_id: that.nav_id,
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							that.list = _d.data.list[0]
							const regex = new RegExp('<img', 'gi')
							that.list.content = that.list.content.replace(regex,"<img style='width:100%'");
						} else if (_d.data.status == 409) {
				
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}else{
				axios.post(API_LIST.banner_content, {
						banner_id: that.nav_id,
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							that.list = _d.data.list[0]
							const regex = new RegExp('<img', 'gi')
							that.list.content = that.list.content.replace(regex,"<img style='width:100%'");
						} else if (_d.data.status == 409) {
				
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		},
		mounted() {

		},

		methods: {
			
		}
	};
</script>

<style scoped="scoped" lang="scss">
	.articeDetail{
		width: 850px;
		// height: 20px;
		margin: 40px 0 0 0;
		.tops{
			display: flex;
			flex-direction: row;
			align-items: center;
			img{
				width: 130px;
				height: 44px;
				margin: 0 20px 0 0;
			}
			span{
				font-size: 18px;
			}
		}
		h2{
			font-size: 40px;
		}
		.title{
			font-size: 16px;
			font-family: MicrosoftYaHei;
			color: #999999;
			margin: 8px 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.image{
			width: 100%;
			margin: 30px 0 0 0;
		}
		.dao{
			margin: 30px 0 0 0;
			padding: 10px 0;
			font-size: 18px;
			color: #999999;
			span{
				color: #999999;
			}
		}
		.Html{
			margin: 20px 0 0 0;
			::v-deep img{
				width: 100%;
			}
		}
		
	}
</style>
